import { waitForTransactionReceipt, writeContract } from 'wagmi/actions';
import { Abi } from 'viem';
import { useAccount } from 'wagmi';
import { useDispatch } from 'react-redux';

import { config } from '#core/wagmi';
import api from '#adapter/api/main';
import { useAmplitude } from '#adapter/amplitude/use-amplitude';

import { EClaimStatus } from './colomns';

interface IHandleTaskClaimParams {
    id?: number | null,
    trackClaim?: string | null
}

export const MAKING_TRANSACTION = 'Making Transaction. Please check your device with Wallet Application, and confirm transaction on your screen';
export type THandleTaskClaimFunction = (
    params: IHandleTaskClaimParams,
    status: (status: EClaimStatus, customMessage?: string) => void
) => Promise<void>;

export const useHandleTaskClaim = ({
    abi
}: {
    abi: Abi
}) => {
    const { address, connector } = useAccount();
    const { data: activeTaskRewards } = api.useApiV1TasksRewardsGetQuery();
    const { track } = useAmplitude();
    const dispatch = useDispatch();
    const handleTaskClaim: THandleTaskClaimFunction = async (
        { id, trackClaim },
        status
    ) => {
        try {
            if(!address || !connector) {
                status(EClaimStatus.WalletNotConnected);

                return;
            }

            const foundPermit = activeTaskRewards?.active_permits.find((p) => p?.id === id && p?.status !== 'completed');

            if(!activeTaskRewards?.active_permits?.length || !foundPermit) {
                status(EClaimStatus.NoPermit);

                return;
            }

            const {
                token_address = '',
                amount_blockchain = 0,
                deadline_unix_time = 0,
                permit_signature = '',
                pw3_platform_task_rewards_contract_address = ''
            } = foundPermit;

            if(!token_address || !deadline_unix_time || !permit_signature || !amount_blockchain) {
                status(EClaimStatus.NoPermit);

                return;
            }

            status(EClaimStatus.MakingTransaction);
            const hash = await writeContract(config, {
                abi,
                address     : pw3_platform_task_rewards_contract_address as `0x${string}`,
                functionName: 'withdrawFunds',
                account     : address,
                args        : [token_address, amount_blockchain, deadline_unix_time, permit_signature],
                connector
            });

            status(EClaimStatus.Waiting);
            await waitForTransactionReceipt(config, {
                hash
            });

            if(trackClaim) {
                track(trackClaim, {
                    wallet_name: connector?.name,
                    wallet_id  : connector?.id,
                    amount     : amount_blockchain
                });
            }

            status(EClaimStatus.Success, hash);
            // @ts-expect-error
            dispatch(api.util.invalidateTags(['apiV1TasksRewardsGet']));
        } catch(error) {
            status(EClaimStatus.Error, `${error}`);
        }
    };

    return handleTaskClaim;
};
