import React, { useEffect } from 'react';

import { useSelector, useDispatch } from '#core/store';
import { useClassnames } from '#hook/use-classnames';
import { useWagmiFlow } from '#hook/use-wagmi-flow';

import Modal from '#component/modal';

import { FlowSteps } from '../flow-steps';
import { slice } from './slice';

import styles from './index.module.pcss';

export const FlowModal = () => {
    const { isOpen, disabled } = useSelector((store) => ({
        isOpen  : store[slice.name].isOpen,
        disabled: store[slice.name].disabled
    }));
    const dispatch = useDispatch();
    const cn = useClassnames(styles);
    const { hasNeedConnectWallet } = useWagmiFlow();

    const closed = () => {
        dispatch(
            slice.actions.set({
                isOpen: false
            })
        );
    };

    useEffect(() => {
        if(!hasNeedConnectWallet) {
            closed();
        }
    }, [hasNeedConnectWallet]);

    if(!isOpen || disabled) {
        return null;
    }

    return (
        <Modal
            className={{
                'modal__icon-close': cn('flow-modal__icon-close'),
                'modal__children'  : cn('flow-modal__children'),
                'modal__body'      : cn('flow-modal__body')
            }}
            onClose={() => {
                closed();
            }}
        >
            <FlowSteps />
        </Modal>
    );
};
