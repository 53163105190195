import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import { type TStyle, useClassnames } from '#hook/use-classnames';
import { useWindowResize } from '#hook/use-window-resize';
import { useDispatch, useSelector } from '#core/store';
import { useWeb3Modal } from '#hook/use-web3-modal';
import { useWagmiFlow } from '#hook/use-wagmi-flow';

import Modal from '#component/modal';
import { slice as sliceFlowModal } from '#component/wagmi/flow-modal/slice';
import { slice as sliceMediaQuery } from '#component/media-query/slice';
import { Button } from '#component/button';
import IconCoin from '#component/icons/coin';

import { slice } from '../../slice';
import giftImage from './image/gift.png';
import bgImage from './image/bg.png';

import style from './index.module.pcss';

export interface IProps {
    className?: TStyle | string
}

let COUNT_START = 0;

const BANNER_MAX_HEIGHT = 604;

export const WalletConnectBanner = (props: IProps) => {
    const cn = useClassnames(style, props.className);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isConnected } = useAccount();
    const web3Modal = useWeb3Modal();
    const { hasNeedFlow, hasNeedConnectWallet } = useWagmiFlow();

    const COOKIE_NAME_SHOW_COUNT = 'cookie-wallet-connect-banner-shown';
    const COOKIE_WALLET_CONNECTED = 'cookie-wallet-bannerconnected';
    const COOKIE_BLOCK = 'cookie-wallet-banner-block';
    const cookieShowCount = Cookies.get(COOKIE_NAME_SHOW_COUNT);
    const cookieWalletConnected = Cookies.get(COOKIE_WALLET_CONNECTED);
    const cookieBlock = Cookies.get(COOKIE_BLOCK);
    const parsedShowCount = cookieShowCount ? parseInt(cookieShowCount, 10) : 0;
    const isDesktop = useSelector((store) => store[sliceMediaQuery.name].isDesktop);
    const open = useSelector((state) => state[slice.name].opened);

    useEffect(() => {
        if(parsedShowCount > 2 || cookieBlock || isConnected || cookieWalletConnected) {
            dispatch(slice.actions.set(false));
        } else {
            dispatch(slice.actions.set(true));
        }
    }, []);

    useEffect(() => {
        if(!open) {
            dispatch(
                sliceFlowModal.actions.set({
                    isOpen: false
                })
            );
        }
    }, [open]);

    useEffect(() => {
        if(!hasNeedFlow && open) {
            Cookies.set(COOKIE_WALLET_CONNECTED, 'true');

            dispatch(slice.actions.set(false));
        }
    }, [hasNeedFlow, open]);

    const [, height] = useWindowResize();

    const onClickClose = () => {
        const incrementCount = parsedShowCount ? parsedShowCount + 1 : COUNT_START + 1;

        Cookies.set(COOKIE_NAME_SHOW_COUNT, String(incrementCount));

        if(incrementCount > 2) {
            const endDate = endOfDay(new Date());

            Cookies.set(COOKIE_BLOCK, 'true', { expires: endDate });
        }

        COUNT_START = incrementCount;

        dispatch(slice.actions.set(false));
    };

    const elIconClose = () => {
        return (
            <div className={cn('marketing-banner__icon-close-container')}>
                <svg
                    viewBox="0 0 75 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={cn('marketing-banner__icon-close', {
                        'marketing-banner__icon-close_height': height - 160 < BANNER_MAX_HEIGHT
                    })}
                    onClick={onClickClose}
                >
                    <line
                        x1="5.76777"
                        y1="2.40801"
                        x2="72.9429"
                        y2="69.5832"
                        stroke="white"
                        strokeWidth="5"
                    />
                    <line
                        x1="2.23223"
                        y1="69.408"
                        x2="69.4074"
                        y2="2.23287"
                        stroke="white"
                        strokeWidth="5"
                    />
                </svg>
            </div>
        );
    };

    if(!open) {
        return null;
    }

    const elContent = () => {
        return (
            <div
                style={{
                    height         : isDesktop ? `${height * 0.8}px` : undefined,
                    maxHeight      : isDesktop ? `${BANNER_MAX_HEIGHT}px` : undefined,
                    backgroundImage: `url(${bgImage})`
                }}
                className={cn('marketing-banner__content')}
                onClick={() => {
                    web3Modal.open();
                }}
                role="button"
                tabIndex={0}
            >
                {elIconClose()}
                <img
                    src={giftImage}
                    alt="gift"
                    className={cn('marketing-banner__gift')}
                />
                <div
                    className={cn('marketing-banner__content-inner')}
                >
                    <h2 className={cn('marketing-banner__content-title')}>
                        {t('components.banner.marketing.wallet-connect.title')}
                    </h2>
                    <div className={cn('marketing-banner__content-coin')}>
                        <IconCoin
                            width={40}
                            height={40}
                        />
                        <span className={cn('marketing-banner__content-coin-count')}>5</span>
                        <span className={cn('marketing-banner__content-coin-name')}>$PW3_CS</span>
                    </div>
                    <Button
                        presetStyle="primary-violet"
                        uppercase={true}
                        presetSize="large"
                        className={cn('marketing-banner__content-button')}
                        onClick={() => {
                            web3Modal.open();

                            // dispatch(slice.actions.set(false));
                        }}
                    >
                        {t('components.banner.marketing.wallet-connect.button', {
                            context: hasNeedConnectWallet ? 'wallet' : 'continue'
                        })}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <Modal
            onClose={onClickClose}
            isNoBackground={true}
            isCloseButtonDisabled={true}
            disableOutsideClick={true}
            className={{
                'modal'          : cn('marketing-banner__modal'),
                'modal__children': cn('marketing-banner__modal-children'),
                'modal__body'    : cn('marketing-banner__modal-body')
            }}
        >
            {elContent()}
        </Modal>
    );
};
