import React, { Fragment, useMemo, useState } from 'react';
import { useAccount, useSignMessage } from 'wagmi';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import api from '#adapter/api/main';
import { useClassnames } from '#hook/use-classnames';

import { Button } from '#component/button';

import styles from './index.module.pcss';

export const Sign = () => {
    const { address, connector } = useAccount();
    const { signMessageAsync, isPending } = useSignMessage();
    const { t } = useTranslation();
    const [nonceRequest] = api.endpoints.apiV1AuthNoncePost.useMutation();
    const [authenticateRequest] = api.endpoints.apiV1UsersAuthenticatePost.useMutation();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const cn = useClassnames(styles);

    const elError = useMemo(() => {
        if(error) {
            return <div className={cn('sign__error')}>{error}</div>;
        }
    }, [error]);

    const elContent = () => {
        if(!address) {
            return <div className={cn('sign__error')}>{t('components.wagmi.sign.error-address')}</div>;
        }

        return (
            <Fragment>
                <p className={cn('sign__text')}>{t('components.wagmi.sign.text')}</p>
                <Button
                    className={cn('sign__button')}
                    disabled={isLoading || isPending}
                    presetStyle="primary-violet"
                    onClick={async (e) => {
                        e.preventDefault();

                        if(!isLoading) {
                            setIsLoading(true);
                            setError(null);

                            try {
                                const { nonce } = await nonceRequest({
                                    body: {
                                        user_address: address
                                    }
                                }).unwrap();

                                const signature = await signMessageAsync({
                                    message: `I am signing this message to confirm that I am authorized on PlayWeb3.io\n\nAuthenticate: ${nonce}`,
                                    connector
                                });

                                void authenticateRequest({
                                    body: {
                                        address,
                                        signature,
                                        nonce: nonce as string
                                    }
                                })
                                    .unwrap()
                                    .then(() => {
                                        api.util.resetApiState();
                                    });
                            } catch(error) {
                                setError((error as Error).message);
                            }

                            setIsLoading(false);
                        }
                    }}
                >
                    {isLoading || isPending ? <Spin size="small" /> : t('components.wagmi.sign.button')}
                </Button>
            </Fragment>
        );
    };

    return (
        <div className={cn('sign')}>
            <h2 className={cn('sign__title')}>{t('components.wagmi.sign.title')}</h2>
            <div className={cn('sign__content')}>
                {elContent()}
            </div>
            {elError}
        </div>
    );
};
