
import * as amplitudeSource from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { autocapturePlugin } from '@amplitude/plugin-autocapture-browser';
import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';

import { useWagmiFlow } from '#hook/use-wagmi-flow';
import { getDataTWA } from '#hook/get-data-twa';

export const useAmplitude = () => {
    const { isLoading, hasNeedConnectWallet } = useWagmiFlow();
    const [amplitude, setAmplitude] = useState<typeof amplitudeSource | null>(null);
    const { isTelegram } = getDataTWA() || { isTelegram: false };
    const { connector, address } = useAccount();

    useEffect(() => {
        if(!hasNeedConnectWallet && !isLoading && !amplitude && import.meta.env.APP_AMPLITUDE) {
            void amplitudeSource
                .init(import.meta.env.APP_AMPLITUDE)
                .promise
                .then(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    instance.track(isTelegram ? 'tgapp_wallet_connected' : 'wallet_connected', {
                        wallet_name   : connector?.name,
                        wallet_address: address
                    });
                });

            amplitudeSource.add(
                sessionReplayPlugin({
                    sampleRate: 1
                })
            );

            amplitudeSource.add(autocapturePlugin());

            const instance = amplitudeSource;

            setAmplitude(instance);
        }
    }, [hasNeedConnectWallet, isLoading, amplitude]);

    const track = (eventName: string, eventProperties?: Record<string, unknown>) => {
        if(!hasNeedConnectWallet && amplitude) {
            amplitude.track(eventName, eventProperties);
        }
    };

    return { track };
};
