import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import i18n from '#locale';

import type { api as apiType } from '.';

export const baseQuery = (baseUrl: string) => {
    return fetchBaseQuery({
        baseUrl,
        headers       : { 'cache-control': 'no-cache' },
        prepareHeaders: (headers) => {
            headers.set('x-lang', i18n.language.toUpperCase());

            return headers;
        }
    });
};

export const enhanceEndpoints = (api: typeof apiType) => {
    api.enhanceEndpoints({
        addTagTypes: ['apiV1UsersWhoamiGet', 'apiV1StatisticsUserProfileGet', 'apiV1TasksRewardsGet'],
        endpoints  : {
            apiV1StatisticsUserProfileGet: {
                providesTags: ['apiV1StatisticsUserProfileGet']
            },
            apiV1UsersWhoamiGet: {
                providesTags: ['apiV1UsersWhoamiGet']
            },
            apiV1UsersAuthenticatePost: {
                invalidatesTags: ['apiV1UsersWhoamiGet', 'apiV1StatisticsUserProfileGet']
            },
            apiV1UsersLogoutPost: {
                invalidatesTags: ['apiV1UsersWhoamiGet', 'apiV1StatisticsUserProfileGet']
            },
            apiV1TasksRewardsGet: {
                providesTags: ['apiV1TasksRewardsGet']
            },
            apiV1TasksInstancesGet: {
                keepUnusedDataFor: 0
            }
        }
    });
};
