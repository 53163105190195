/*
    This file was automatically generated.
    DO NOT MODIFY IT BY HAND
    Instead, modify the source.

    Signature: bfa83bb606387e6775fc3c91b8523f170c9dd916e37e9bb287a7d10441c49563
*/

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery, enhanceEndpoints } from './extension';
import type * as apiV1UsersWhoamiGet from './api/v1/users/whoami/get';
import type * as apiV1UsersLogoutPost from './api/v1/users/logout/post';
import type * as apiV1UsersTrackGet from './api/v1/users/track/get';
import type * as apiV1GamesGet from './api/v1/games/get';
import type * as apiV1GamesGameIdGet from './api/v1/games/{game_id}/get';
import type * as apiV1IgosGet from './api/v1/igos/get';
import type * as apiV1IgosIgoIdGet from './api/v1/igos/{igo_id}/get';
import type * as apiV1ImagesGet from './api/v1/images/get';
import type * as apiV1ImagesPost from './api/v1/images/post';
import type * as apiV1ImagesImageIdGet from './api/v1/images/{image_id}/get';
import type * as apiV1PartnersGet from './api/v1/partners/get';
import type * as apiV1PartnersPartnerIdGet from './api/v1/partners/{partner_id}/get';
import type * as apiV1TokenDataGet from './api/v1/token_data/get';
import type * as apiV1TokenDataTokenDataIdGet from './api/v1/token_data/{token_data_id}/get';
import type * as apiV1PlayGet from './api/v1/play/get';
import type * as apiV1PlayPlayIdGet from './api/v1/play/{play_id}/get';
import type * as apiV1AirdropsGet from './api/v1/airdrops/get';
import type * as apiV1AirdropsAirdropIdGet from './api/v1/airdrops/{airdrop_id}/get';
import type * as apiV1GameItemsGet from './api/v1/game_items/get';
import type * as apiV1GameItemsGameItemIdGet from './api/v1/game_items/{game_item_id}/get';
import type * as apiV1GameItemsPurchaseArtifactPost from './api/v1/game_items/purchase_artifact/post';
import type * as apiV1PostsGet from './api/v1/posts/get';
import type * as apiV1PostsPost from './api/v1/posts/post';
import type * as apiV1PostsPostIdGet from './api/v1/posts/{post_id}/get';
import type * as apiV1PostsRelatedPostIdGet from './api/v1/posts/related/{post_id}/get';
import type * as apiV1PostsPostIdTranslationsPost from './api/v1/posts/{post_id}/translations/post';
import type * as apiV1PostsPostIdTranslationsGet from './api/v1/posts/{post_id}/translations/get';
import type * as apiV1TasksGet from './api/v1/tasks/get';
import type * as apiV1TasksInstancesGet from './api/v1/tasks/instances/get';
import type * as apiV1TasksInstancesUpdateProgressPost from './api/v1/tasks/instances/update-progress/post';
import type * as apiV1AffiliateRegisterAffiliatePost from './api/v1/affiliate/register-affiliate/post';
import type * as apiV1TokenPricesGet from './api/v1/token-prices/get';
import type * as apiV1StatisticsUserGet from './api/v1/statistics/user/get';
import type * as apiV1StatisticsGeneralGet from './api/v1/statistics/general/get';
import type * as apiV1StatisticsPlayWeb3LaunchpadGet from './api/v1/statistics/play-web3-launchpad/get';
import type * as apiV1StatisticsPlayWeb3RoyaltiesDistributionGet from './api/v1/statistics/play_web3_royalties_distribution/get';
import type * as apiV1StatisticsCirculatingSupplyGet from './api/v1/statistics/circulating-supply/get';
import type * as apiV1StatisticsGameTokensAirdropsGet from './api/v1/statistics/game-tokens-airdrops/get';
import type * as apiV1StatisticsBurningStatisticsGet from './api/v1/statistics/burning_statistics/get';
import type * as apiV1StatisticsGameTokensPerformanceGet from './api/v1/statistics/game_tokens_performance/get';
import type * as apiV1StatisticsPlayWeb3GameTokensGet from './api/v1/statistics/play-web3-game-tokens/get';
import type * as apiV1StatisticsGameTokensGet from './api/v1/statistics/game-tokens/get';
import type * as apiV1StatisticsTokenPriceGameTokenIdGet from './api/v1/statistics/token-price/{game_token_id}/get';
import type * as apiV1StatisticsGameTokensGameTokenIdGet from './api/v1/statistics/game-tokens/{game_token_id}/get';
import type * as apiV1StatisticsUserProfileGet from './api/v1/statistics/user-profile/get';
import type * as languagesGet from './languages/get';
import type * as apiV1TokenDataMainGet from './api/v1/token_data/main/get';
import type * as apiV1AuthNoncePost from './api/v1/auth/nonce/post';
import type * as apiV1AuthVerifyNoncePost from './api/v1/auth/verify_nonce/post';
import type * as apiV1UsersAuthenticatePost from './api/v1/users/authenticate/post';
import type * as apiV1RevenueStreamsGet from './api/v1/revenue_streams/get';
import type * as apiV1RevenueStreamsIgoPaymentIdGet from './api/v1/revenue_streams/{igo_payment_id}/get';
import type * as apiV1GamesFeedbackPost from './api/v1/games/feedback/post';
import type * as apiV1AffiliateWelcomeAffiliatePost from './api/v1/affiliate/welcome-affiliate/post';
import type * as apiV1TasksInstancesEventPost from './api/v1/tasks/instances/event/post';
import type * as apiV1TasksInstancesEventsGet from './api/v1/tasks/instances/events/get';
import type * as apiV1TasksProcessRewardsPost from './api/v1/tasks/process_rewards/post';
import type * as apiV1TasksConnectTwitterPost from './api/v1/tasks/connect_twitter';
import { IRewardsResponse } from './api/v1/tasks/rewards';

export const api = createApi({
    reducerPath: 'api/main',
    baseQuery  : baseQuery('/'),
    endpoints  : (build) => ({
        apiV1UsersWhoamiGet: build.query<apiV1UsersWhoamiGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/users/whoami',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1UsersLogoutPost: build.mutation<apiV1UsersLogoutPost.TCode200, void>({
            query: () => ({
                url   : '/api/v1/users/logout/',
                method: 'POST',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1GamesGet: build.query<apiV1GamesGet.TCode200, apiV1GamesGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/games/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1GamesGameIdGet: build.query<apiV1GamesGameIdGet.ICode200, apiV1GamesGameIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/games/${params.path.game_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1IgosGet: build.query<apiV1IgosGet.TCode200, apiV1IgosGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/igos/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit, exclude_id: params?.query?.exclude_id, status: params?.query?.status, game_id: params?.query?.game_id },
                body  : undefined
            })
        }),
        apiV1IgosIgoIdGet: build.query<apiV1IgosIgoIdGet.ICode200, apiV1IgosIgoIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/igos/${params.path.igo_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1ImagesGet: build.query<apiV1ImagesGet.TCode200, apiV1ImagesGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/images/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1ImagesPost: build.mutation<apiV1ImagesPost.ICode200, apiV1ImagesPost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/images/',
                method: 'POST',
                params: { kind: params.query?.kind, post_id: params.query?.post_id, how_to_play_id: params.query?.how_to_play_id, play_id: params.query?.play_id, airdrop_id: params.query?.airdrop_id, game_id: params.query?.game_id, igo_id: params.query?.igo_id, save: params.query?.save },
                body  : params.body
            })
        }),
        apiV1ImagesImageIdGet: build.query<apiV1ImagesImageIdGet.ICode200, apiV1ImagesImageIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/images/${params.path.image_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1PartnersGet: build.query<apiV1PartnersGet.TCode200, apiV1PartnersGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/partners/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit, kind: params?.query?.kind },
                body  : undefined
            })
        }),
        apiV1PartnersPartnerIdGet: build.query<apiV1PartnersPartnerIdGet.ICode200, apiV1PartnersPartnerIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/partners/${params.path.partner_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1TokenDataGet: build.query<apiV1TokenDataGet.TCode200, apiV1TokenDataGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/token_data/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1TokenDataMainGet: build.query<apiV1TokenDataMainGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/token_data/main',
                method: 'GET',
                body  : undefined
            })
        }),
        apiV1TokenDataTokenDataIdGet: build.query<apiV1TokenDataTokenDataIdGet.ICode200, apiV1TokenDataTokenDataIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/token_data/${params.path.token_data_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1PlayGet: build.query<apiV1PlayGet.TCode200, apiV1PlayGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/play/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1PlayPlayIdGet: build.query<apiV1PlayPlayIdGet.ICode200, apiV1PlayPlayIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/play/${params.path.play_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1AirdropsGet: build.query<apiV1AirdropsGet.TCode200, apiV1AirdropsGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/airdrops/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1AirdropsAirdropIdGet: build.query<apiV1AirdropsAirdropIdGet.ICode200, apiV1AirdropsAirdropIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/airdrops/${params.path.airdrop_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1GameItemsGet: build.query<apiV1GameItemsGet.TCode200, apiV1GameItemsGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/game_items/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1GameItemsGameItemIdGet: build.query<apiV1GameItemsGameItemIdGet.ICode200, apiV1GameItemsGameItemIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/game_items/${params.path.game_item_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1GameItemsPurchaseArtifactPost: build.mutation<apiV1GameItemsPurchaseArtifactPost.ICode200, apiV1GameItemsPurchaseArtifactPost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/game_items/purchase_artifact/',
                method: 'POST',
                params: { game_item_id: params.query.game_item_id, user_wallet: params.query.user_wallet, txhash: params.query.txhash, count: params.query.count },
                body  : undefined
            })
        }),
        apiV1PostsGet: build.query<apiV1PostsGet.TCode200, apiV1PostsGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/posts/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit, developer_id: params?.query?.developer_id, game_id: params?.query?.game_id },
                body  : undefined
            })
        }),
        apiV1PostsPost: build.mutation<apiV1PostsPost.ICode200, apiV1PostsPost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/posts/',
                method: 'POST',
                params: undefined,
                body  : { user_id: params.body.user_id, developer_id: params.body.developer_id, game_id: params.body.game_id, status: params.body.status, images: params.body.images, translated_title: params.body.translated_title, translated_body: params.body.translated_body, translated_blocks: params.body.translated_blocks, translations: params.body.translations }
            })
        }),
        apiV1PostsPostIdGet: build.query<apiV1PostsPostIdGet.ICode200, apiV1PostsPostIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/posts/${params.path.post_id}`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1PostsRelatedPostIdGet: build.query<apiV1PostsRelatedPostIdGet.TCode200, apiV1PostsRelatedPostIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/posts/related/${params.path.post_id}`,
                method: 'GET',
                params: { skip: params.query?.skip, limit: params.query?.limit },
                body  : undefined
            })
        }),
        apiV1PostsPostIdTranslationsPost: build.mutation<apiV1PostsPostIdTranslationsPost.ICode200, apiV1PostsPostIdTranslationsPost.IParameters>({
            query: (params) => ({
                url   : `/api/v1/posts/${params.path.post_id}/translations/`,
                method: 'POST',
                params: undefined,
                body  : { lang_code: params.body?.lang_code, body: params.body?.body }
            })
        }),
        apiV1PostsPostIdTranslationsGet: build.query<apiV1PostsPostIdTranslationsGet.TCode200, apiV1PostsPostIdTranslationsGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/posts/${params.path.post_id}/translations/`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1TasksGet: build.query<apiV1TasksGet.TCode200, apiV1TasksGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/tasks/',
                method: 'GET',
                params: { kind: params?.query?.kind, skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1TasksInstancesGet: build.query<apiV1TasksInstancesGet.TCode200, apiV1TasksInstancesGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/tasks/instances/',
                method: 'GET',
                params: { airdrop_id: params?.query?.airdrop_id, game_id: params?.query?.game_id, skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1TasksInstancesUpdateProgressPost: build.mutation<apiV1TasksInstancesUpdateProgressPost.ICode200, apiV1TasksInstancesUpdateProgressPost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/tasks/instances/update-progress',
                method: 'POST',
                params: { task_instance_id: params.query.task_instance_id, steps: params.query.steps },
                body  : undefined
            })
        }),
        apiV1AffiliateRegisterAffiliatePost: build.mutation<apiV1AffiliateRegisterAffiliatePost.TCode200, apiV1AffiliateRegisterAffiliatePost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/affiliate/register-affiliate',
                method: 'POST',
                params: undefined,
                body  : { link_key: params.body.link_key, new_wallet: params.body.new_wallet }
            })
        }),
        apiV1TokenPricesGet: build.query<apiV1TokenPricesGet.TCode200, void>({
            query: () => ({
                url   : '/api/v1/token-prices',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsGeneralGet: build.query<apiV1StatisticsGeneralGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/general/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsUserGet: build.query<apiV1StatisticsUserGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/user/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1UsersTrack: build.query<void, apiV1UsersTrackGet.IParameters>({
            query: (params) => ({
                url   : '/api/v1/users/track',
                method: 'GET',
                params: { page: params.query?.page },
                body  : undefined
            })
        }),
        apiV1StatisticsPlayWeb3LaunchpadGet: build.query<apiV1StatisticsPlayWeb3LaunchpadGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/play-web3-launchpad/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsPlayWeb3RoyaltiesDistributionGet: build.query<apiV1StatisticsPlayWeb3RoyaltiesDistributionGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/play_web3_royalties_distribution/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsCirculatingSupplyGet: build.query<apiV1StatisticsCirculatingSupplyGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/circulating-supply/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsGameTokensAirdropsGet: build.query<apiV1StatisticsGameTokensAirdropsGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/game-tokens-airdrops/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsBurningStatisticsGet: build.query<apiV1StatisticsBurningStatisticsGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/burning_statistics/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsGameTokensPerformanceGet: build.query<apiV1StatisticsGameTokensPerformanceGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/game_tokens_performance/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsPlayWeb3GameTokensGet: build.query<apiV1StatisticsPlayWeb3GameTokensGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/play-web3-game-tokens/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsGameTokensGet: build.query<apiV1StatisticsGameTokensGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/game-tokens/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsTokenPriceGameTokenIdGet: build.query<apiV1StatisticsTokenPriceGameTokenIdGet.TCode200, apiV1StatisticsTokenPriceGameTokenIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/statistics/token-price/${params.path.game_token_id}/`,
                method: 'GET',
                params: { period: params.query?.period },
                body  : undefined
            })
        }),
        apiV1StatisticsGameTokensGameTokenIdGet: build.query<apiV1StatisticsGameTokensGameTokenIdGet.ICode200, apiV1StatisticsGameTokensGameTokenIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/statistics/game-tokens/${params.path.game_token_id}/`,
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1StatisticsUserProfileGet: build.query<apiV1StatisticsUserProfileGet.ICode200, void>({
            query: () => ({
                url   : '/api/v1/statistics/user-profile/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        languagesGet: build.query<languagesGet.TCode200, void>({
            query: () => ({
                url   : '/languages/',
                method: 'GET',
                params: undefined,
                body  : undefined
            })
        }),
        apiV1AuthNoncePost: build.mutation<apiV1AuthNoncePost.TCode200, apiV1AuthNoncePost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/auth/nonce',
                method: 'POST',
                params: undefined,
                body  : { user_address: params.body.user_address }
            })
        }),
        apiV1AuthVerifyNoncePost: build.mutation<apiV1AuthVerifyNoncePost.TCode200, apiV1AuthVerifyNoncePost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/auth/verify_nonce',
                method: 'POST',
                params: { user_address: params.query.user_address, nonce: params.query.nonce },
                body  : undefined
            })
        }),
        apiV1UsersAuthenticatePost: build.mutation<apiV1UsersAuthenticatePost.TCode200, apiV1UsersAuthenticatePost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/users/authenticate',
                method: 'POST',
                params: undefined,
                body  : { address: params.body.address, nonce: params.body.nonce, signature: params.body.signature }
            })
        }),
        apiV1RevenueStreamsGet: build.query<apiV1RevenueStreamsGet.TCode200, apiV1RevenueStreamsGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/revenue_streams/',
                method: 'GET',
                params: { skip: params?.query?.skip, limit: params?.query?.limit },
                body  : undefined
            })
        }),
        apiV1RevenueStreamsIgoPaymentIdGet: build.query<apiV1RevenueStreamsIgoPaymentIdGet.ICode200, apiV1RevenueStreamsIgoPaymentIdGet.IParameters>({
            query: (params) => ({
                url   : `/api/v1/revenue_streams/${params.path?.igo_payment_id}`,
                method: 'GET',
                params: { revenue_stream_id: params.query.revenue_stream_id },
                body  : undefined
            })
        }),
        apiV1GamesFeedbackPost: build.mutation<apiV1GamesFeedbackPost.ICode200, apiV1GamesFeedbackPost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/games/feedback',
                method: 'POST',
                params: { game_id: params.query.game_id, scores: params.query.scores },
                body  : undefined
            })
        }),
        apiV1AffiliateWelcomeAffiliatePost: build.mutation<apiV1AffiliateWelcomeAffiliatePost.TCode200, apiV1AffiliateWelcomeAffiliatePost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/affiliate/welcome-affiliate',
                method: 'POST',
                params: undefined,
                body  : { link_key: params.body.link_key }
            })
        }),
        apiV1TasksInstancesEventPost: build.mutation<apiV1TasksInstancesEventPost.ICode200, apiV1TasksInstancesEventPost.IParameters>({
            query: (params) => ({
                url   : '/api/v1/tasks/instances/event',
                method: 'POST',
                params: undefined,
                body  : { game_id: params.body.game_id, kind: params.body.kind, airdrop_id: params.body.airdrop_id }
            })
        }),
        apiV1TasksProcessRewardsPost: build.mutation<apiV1TasksProcessRewardsPost.TCode200, void>({
            query: () => ({
                url   : '/api/v1/tasks/process_rewards',
                method: 'POST',
                params: undefined,
                body  : undefined
            })
        }),
        // Новый запрос на подключение к Twitter
        apiV1TasksConnectTwitterPost: build.mutation<apiV1TasksConnectTwitterPost.TCode200, void>({
            query: () => ({
                url   : '/api/v1/tasks/connect_twitter',
                method: 'POST',
                params: undefined,
                body  : undefined
            })
        }),
        // Новый запрос на получение rewards
        apiV1TasksRewardsGet: build.query<IRewardsResponse, void>({
            query: () => ({
                url   : '/api/v1/tasks/rewards',
                method: 'GET'
            })
        }),
        apiV1TasksInstancesEventsGet: build.query<apiV1TasksInstancesEventsGet.TCode200, apiV1TasksInstancesEventsGet.IParameters | void>({
            query: (params) => ({
                url   : '/api/v1/tasks/instances/events',
                method: 'GET',
                params: {
                    skip   : params?.query?.skip,
                    limit  : params?.query?.limit,
                    game_id: params?.query?.game_id
                }
            })
        })
    })
});

enhanceEndpoints(api);

export default api;
